import React, { useEffect } from 'react';
import { trackPage } from '../../../services/helpers';
import '../../../styles/style.scss';
import { Auth } from '../Auth/Auth';

export const Register = () => {
  useEffect(() => {
    trackPage("Registration Page")
  }, [])

  return <Auth type="register" />
}
